import { Component, OnInit } from '@angular/core';
import { SignupService } from '../../services/signup.service';
import { WatcherService } from 'src/app/services/watcher.service';

interface Item {
  category: string;
  data: string;
}

@Component({
  selector: 'app-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.css']
})
export class FlagsComponent implements OnInit {
  lang: string = 'en';

  countriesList = [];
  constructor(
    public signUpService: SignupService,
    private watcherService: WatcherService
  ) {
  }

  ngOnInit(): void {
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )

    this.signUpService.getAllCountries().subscribe(response => {
        this.countriesList = response['data'];
    })

  }
}
