import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../../services/signup.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WatcherService } from 'src/app/services/watcher.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import * as AuthActions from '../../store/shared/auth/auth.actions';
import { register } from 'swiper/element/bundle';
import { Swiper } from 'swiper';
import { trustpilotReviews } from 'src/app/shared/contents/trust-pilot/trust-pilot-review';
import { topGlobalStores } from 'src/app/stores/stores-content';
import { MatDialog } from '@angular/material/dialog';
import { YTPlayerDialog } from 'src/app/shared/features/yt-player-dialog/yt-player-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare var $;

interface influencerVideo {
  video_url: string | SafeResourceUrl | null;
  title: string;
  description: string;
  thumbnail: string;
}
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit, AfterViewInit {
  topGlobalStores = topGlobalStores;
  isThereCommercialBanner = true;
  topRatedRouteList = [
    {
      routeFrom: 'US',
      routeFromName: 'United States',
      routeFromFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeTo: 'UK',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routePrice: '$8.53',
    },
    {
      routeFrom: 'AU',
      routeTo: 'US',
      routePrice: '$14.21',
      routeFromName: 'Australia',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
      routeToName: 'United States',
      routeToFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'AU',
      routeTo: 'UK',
      routePrice: '$17.70',
      routeFromName: 'Australia',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    },
    {
      routeFrom: 'UK',
      routeTo: 'US',
      routePrice: '$12.83',
      routeFromName: 'United Kingdom',
      routeFromFlag:
        'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routeToName: 'United States',
      routeToFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'DE',
      routeTo: 'US',
      routePrice: '$6.08',
      routeFromName: 'Germany',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
      routeToName: 'United States',
      routeToFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
    },
    {
      routeFrom: 'DE',
      routeTo: 'UK',
      routePrice: '$6.11',
      routeFromName: 'Germany',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    },
    {
      routeFrom: 'JP',
      routeTo: 'UK',
      routePrice: '$11.30',
      routeFromName: 'Japan',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/japan.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    },
    {
      routeFrom: 'US',
      routeTo: 'AU',
      routePrice: '$12.14',
      routeFromName: 'United States',
      routeFromFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeToName: 'Australia',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/australia.png',
    },
    {
      routeFrom: 'UK',
      routeTo: 'DE',
      routePrice: '$6.08',
      routeFromName: 'United Kingdom',
      routeFromFlag:
        'https://www.shippn.com/assets/img/flags/united-kingdom.png',
      routeToName: 'Germany',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
    },
    {
      routeFrom: 'US',
      routeTo: 'CA',
      routePrice: '$7.69',
      routeFromName: 'United States',
      routeFromFlag:
        'https://www.shippn.com/assets/img/flags/united-states-of-america.png',
      routeToName: 'Canada',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/canada.png',
    },
    {
      routeFrom: 'NL',
      routeTo: 'DE',
      routePrice: '$3.50',
      routeFromName: 'Netherlands',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/netherlands.png',
      routeToName: 'Germany',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/germany.png',
    },
    {
      routeFrom: 'NL',
      routeTo: 'UK',
      routePrice: '$6.99',
      routeFromName: 'Netherlands',
      routeFromFlag: 'https://www.shippn.com/assets/img/flags/netherlands.png',
      routeToName: 'United Kingdom',
      routeToFlag: 'https://www.shippn.com/assets/img/flags/united-kingdom.png',
    },
  ];
  userID;
  token;
  anonymousID;
  cartID;
  isDisabled = true;
  topRatedHostList;
  hostCountries;
  requestNonHostList;
  allCountries;
  countryFrom = 'au';
  countryTo;
  BFMEmail;
  wordpressData;
  instagramToken;
  BFMTab = false;
  shopFromTab = true;
  mobileBFMEmail;
  desktopBFMTab = true;
  desktopShopFromTab = true;
  profileDetails;
  bfmPurchaseList;
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    country: new UntypedFormControl('AW'),
  });
  bfmMailForm = new UntypedFormGroup({
    bfmMail: new UntypedFormControl('', [Validators.required]),
  });
  lang = 'en';
  shoppingFavoriteProducts: any[];
  trustpilotReviews = trustpilotReviews;
  hostCountriesCount = 0;
  isWelcomePage: boolean = false;
  userDetails: any;

  influencerVideos: influencerVideo[] = [
    {
      video_url:
        'https://www.youtube.com/embed/m3Y0G79Efw4?si=uU45EYyXq8UT9c1M&amp;start=28&end=98',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-1.webp',
    },
    {
      video_url:
        'https://www.youtube.com/embed/NZXEr61K46A?si=GIR8wkzz4Vu29SxE&amp;start=87&end=175',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-2.webp',
    },
    {
      video_url:
        'https://www.youtube.com/embed/JTxtPVKjzVQ?si=Ol_5SqyZMs4QcicD&amp;start=45&end=114',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-3.webp',
    },
    {
      video_url:
        'https://www.youtube.com/embed/TQi2YaZ5yNY?si=UPa0n13GOwamEeXa&amp;start=353&end=420',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-4.webp',
    },
    {
      video_url:
        'https://www.youtube.com/embed/GmATn20COus?si=CgVt72QYwbk6Y3O-&amp;start=60&end=180',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-5.webp',
    },
    {
      video_url:
        'https://www.youtube.com/embed/xuLHX_R3g3s?si=v0O8KFGrSFCRv3OB&amp;start=338&end=394',
      title: 'How to Shop from Amazon US to Australia',
      description: 'Description',
      thumbnail: '../assets/img/homepage/influencer-videos/inf-6.webp',
    },
  ];

  constructor(
    private _route: ActivatedRoute,
    public signUpService: SignupService,
    public watcherService: WatcherService,
    public toastr: ToastrService,
    private router: Router,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}
  commercialBannerImage =
    'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_desktop.webp';
  shopInternationallyWorld =
    'assets/img/banners/shop-internationally/desktop_world.webp';
  viewIsDesktop;
  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeWorks();
  }
  private resizeWorks(): void {
    let windowSize = window.innerWidth;
    if (windowSize < 768) {
      this.viewIsDesktop = false;
      this.shopInternationallyWorld =
        'assets/img/banners/shop-internationally/mobile_world.webp';
      this.commercialBannerImage =
        'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_mobile.webp';
    } else {
      this.viewIsDesktop = true;
      this.shopInternationallyWorld =
        'assets/img/banners/shop-internationally/desktop_world.webp';
      this.commercialBannerImage =
        'https://d1we2hgzmci25y.cloudfront.net/assets/img/valentines_day_2024_banner_desktop.webp';
    }

    this.initInfluencerVideoSwiper();
  }
  htmlDecode(input) {
    let e = document.createElement('textarea');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }
  loginWithToken() {
    this._route.queryParams.subscribe((params) => {
      if (params.token && params.userid) {
        localStorage.setItem('token', params.token);
        localStorage.setItem('userId', params.userid);
        this.signUpService
          .getUserByUserID(params.userid, params.token)
          .subscribe((data) => {
            this.profileDetails = data['data'];
            this.store.dispatch(
              AuthActions.LoginSuccess({
                token: params.token,
                user: this.profileDetails,
              })
            );
            this.router.navigate(['/']);
          });
      }
    });
  }
  ngAfterViewInit() {
    // this.signUpService.getTopRatedHostList().subscribe((list) => {
    //   this.topRatedHostList = list['data'];
    //   this.cdr.detectChanges();
    // });
    this.signUpService.getAllCountries().subscribe((list) => {
      this.allCountries = list['data'];
      this.countryTo = 'TR';
      this.requestNonHostList = this.allCountries.filter((country) => {
        return country.is_host === 0;
      });
      this.cdr.detectChanges();
    });
  }
  shoppingProductsRecieved = false;
  step = 1;
  ngOnInit() {
    this.resizeWorks();
    this.initSwiper();
    if (this.isAndrodiWebview() || this.isWebview()) {
      this.platformisWebView = true;
    }
    this.store.select('auth').subscribe((state) => {
      this.token = state.token;
      this.userID = state.user?.id;
      this.userDetails = state.user ?? null;

      this.cdr.detectChanges();
    });
    localStorage.removeItem('confrimedCheckout');
    localStorage.removeItem('confirmedOrderCheckUrl');
    localStorage.removeItem('confirmedOrderCheckPrice');
    this.watcherService.getLang().subscribe((lang) => {
      this.lang = lang;
      this.cdr.detectChanges();
    });
    this.watcherService.getPurchasesResponse().subscribe((data) => {
      this.bfmPurchaseList = data['data'];
      this.cdr.detectChanges();
    });
    this._route.queryParams.subscribe((params) => {
      if (params?.welcome && params?.welcome === 'shippn') {
        this.isWelcomePage = true;
      } else {
        this.isWelcomePage = false;
      }
    });
    this.loginWithToken();
    if (this.token) {
      this.signUpService
        .getUserByUserID(this.userID, this.token)
        .subscribe((data) => {
          this.profileDetails = data['data'];
          this.form.patchValue({ email: this.profileDetails.email });
          this.form.controls.email.disable();
          // If user session was ended in BFM Checkout Redirect user to BFM Checkout
          let anonymous_id = localStorage.getItem('anonymous_id');
          let cart_id = localStorage.getItem('cart_id');
          let bfm_country_from = localStorage.getItem('bfm_country_from');
          let bfm_country_to = localStorage.getItem('bfm_country_to');
          let bfm_shopper_id = localStorage.getItem('bfm_shopper_id');
          let userType = data['data']['type'];
          let isPhoneVerified = data['data']['is_phone_verified'];
          let isEmailVerified = data['data']['is_email_verified'];
          let sessionUserId = data['data']['id'];

          // Check if user verified
          if (
            anonymous_id &&
            cart_id &&
            userType === 1 &&
            isPhoneVerified === 1 &&
            isEmailVerified === 1 &&
            sessionUserId === bfm_shopper_id
          ) {
            localStorage.removeItem('anonymous_id');
            localStorage.removeItem('cart_id');
            localStorage.removeItem('bfm_country_from');
            localStorage.removeItem('bfm_country_to');
            localStorage.removeItem('bfm_shopper_id');
            this.router.navigate(['/', this.lang, 'bfm', 'checkout'], {
              queryParams: {
                anonymous_id: anonymous_id,
                cart_id: cart_id,
                country_from: bfm_country_from,
                country_to: bfm_country_to,
                shopper_id: bfm_shopper_id,
              },
            });
          }
          // If user session was ended in BFM Checkout Redirect user to BFM Checkout

          let orderID = localStorage.getItem('orderDetailID');
          let orderUserID = localStorage.getItem('orderDetailUserId');
          if (orderID && userType === 1 && sessionUserId === orderUserID) {
            localStorage.removeItem('orderDetailID');
            localStorage.removeItem('orderDetailUserId');
            this.router.navigate([
              '/',
              this.lang,
              'member',
              'order-details',
              orderID,
            ]);
          }
        });
    }
    this.watcherService.getHostCountriesResponse().subscribe({
      next: (list) => {
        if (list['data']) {
          this.hostCountries = list['data'];
          this.hostCountriesCount = 0;
          this.startAnimation();
          this.countryFrom = list['data'][0]['code'];
          this.cdr.detectChanges();
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, 'Error');
      },
    });
  }

  startAnimation() {
    const intervalId = setInterval(() => {
      if (this.hostCountriesCount === this.hostCountries.length) {
        clearInterval(intervalId);
      } else {
        this.hostCountriesCount++;
        this.cdr.detectChanges();
      }
    }, 50);
  }
  goLocation(path) {
    if (this.userDetails?.has_orders === false) {
      return this.router.navigate(['/', this.lang, 'shop-from', path], {
        queryParams: { user: 'new' },
      });
    }

    path = path.toLowerCase();
    if (path !== 'howitworks-for-shoppers') {
      this.router.navigate(['/', this.lang, 'shop-from', path]);
    } else {
      this.router.navigate(['/', this.lang, 'howitworks-for-shoppers']);
    }
  }
  test(countryCode) {
    if (countryCode === 'other') {
      $('#hostCountryRequestModal').modal('show');
      setTimeout(() => {
        this.countryFrom = 'AU';
      }, 500);
    }
  }
  checkBFMTabValidations() {
    let hasError = false;
    if (this.bfmMailForm.controls.bfmMail.status === 'INVALID') {
      this.bfmMailForm.controls.bfmMail.markAsTouched();
      this.toastr.error(
        'Please enter the proper product link to continue',
        'Error'
      );
      hasError = true;
    }
    return hasError;
  }
  goBFM() {
    if (this.checkBFMTabValidations() === false) {
      let userData = {
        source: 'web',
      };
      this.signUpService
        .getAnonymousID(this.token, userData)
        .subscribe((data) => {
          this.anonymousID = data['anonymous_id'];
          let userDataShoppingCart = {
            anonymous_id: this.anonymousID,
          };
          this.signUpService
            .getBFMShoppingCart(this.token, userDataShoppingCart)
            .subscribe((data) => {
              this.cartID = data['shopping_cart_id'];
              this.router.navigate(['/', this.lang, 'bfm'], {
                queryParams: {
                  anonymous_id: this.anonymousID,
                  cart_id: this.cartID,
                  link: this.bfmMailForm.value.bfmMail,
                },
              });
            });
        });
    }
  }
  goBFMExplanation() {
    this.router.navigate(['/', this.lang, 'bfm', 'explanation']);
  }
  checkValidations() {
    let hasError = false;
    if (this.form.controls.email.status === 'INVALID') {
      this.form.controls.email.markAsTouched();
      hasError = true;
    }
    return hasError;
  }
  saveHostRequest() {
    if (this.checkValidations() === false) {
      let userData = {
        email: this.form.value.email,
        country_code: this.form.value.country,
      };
      this.signUpService
        .sendHostRequest(this.token, userData)
        .subscribe((data) => {
          if (data['result']['status'] === 200) {
            this.toastr.success(data['result']['message'], 'Success');
            $('#hostCountryRequestModal').modal('hide');
            if (!this.token) {
              this.form.reset();
              this.form.patchValue({ country: 'AW' });
            }
            this.cdr.detectChanges();
          } else {
            this.toastr.error(data['result']['message'], 'Error');
          }
        });
    }
  }
  trackByIndex(index: number): any {
    return index;
  }
  init: boolean = false;
  influencerVideoSwiper;
  initSwiper() {
    const swiperBannerEl = document.getElementById('shippn-main-banner-swiper');
    Object.assign(swiperBannerEl, {
      slidesPerView: 1,
      spaceBetween: 0,
      slidesPerGroup: 1,
      nextButton: '#mainBannerNext',
      prevButton: '#mainBannerPrev',
      breakpoints: {
        '768': {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
        '992': {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
        '1200': {
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
        },
      },
    });
    const swiperRouteEl = document.getElementById('shippn-rated-routes-swiper');
    const buttonNextEl = document.getElementById('topRoute-next');
    buttonNextEl.addEventListener('click', () => {
      swiperRouteEl['swiper'].slideNext();
    });
    const buttonPrevEl = document.getElementById('topRoute-prev');
    buttonPrevEl.addEventListener('click', () => {
      swiperRouteEl['swiper'].slidePrev();
    });
    Object.assign(swiperRouteEl, {
      slidesPerView: 1,
      spaceBetween: 10,
      slidesPerGroup: 1,
      nextButton: '#topRoute-next',
      prevButton: '#topRoute-prev',
      breakpoints: {
        '420': {
          slidesPerView: 1,
          spaceBetween: 10,
          slidesPerGroup: 1,
        },
        '576': {
          slidesPerView: 2,
          spaceBetween: 10,
          slidesPerGroup: 2,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 15,
          slidesPerGroup: 2,
        },
        '992': {
          slidesPerView: 3,
          spaceBetween: 20,
          slidesPerGroup: 3,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 40,
          slidesPerGroup: 3,
        },
        '1200': {
          slidesPerView: 4,
          spaceBetween: 50,
          slidesPerGroup: 4,
        },
      },
    });
    const trustPilotReviewsSwiperEl = document.getElementById(
      'trustPilotReviewsSwiper'
    );
    const tprsNextEl = document.getElementById('trustPilotReviewsSwiper-next');
    const tprsPrevEl = document.getElementById('trustPilotReviewsSwiper-prev');

    tprsNextEl.addEventListener('click', () => {
      trustPilotReviewsSwiperEl['swiper'].slideNext();
    });
    tprsPrevEl.addEventListener('click', () => {
      trustPilotReviewsSwiperEl['swiper'].slidePrev();
    });

    Object.assign(trustPilotReviewsSwiperEl, {
      slidesPerView: 'auto',
      spaceBetween: 20,
      loop: true,
      nextButton: '#trustPilotReviewsSwiper-next',
      prevButton: '#trustPilotReviewsSwiper-prev',
      breakpoints: {
        '420': {
          spaceBetween: 10,
          slidesPerView: 'auto',
        },
        '576': {
          spaceBetween: 10,
          slidesPerView: 'auto',
        },
        '768': {
          spaceBetween: 20,
          slidesPerView: 'auto',
        },
      },
    });

    setTimeout(() => {
      register();
    }, 100);
  }

  initInfluencerVideoSwiper() {
    if (window.innerWidth <= 768) {
      if (!this.init) {
        const influencerVideoSwiperEl = document.getElementById(
          'influencerVideosSwiper'
        );
        const ivsNextEl = document.getElementById('influencerVideosSwiper-next');
        const ivsPrevEl = document.getElementById('influencerVideosSwiper-prev');

        ivsNextEl.addEventListener('click', () => {
          influencerVideoSwiperEl['swiper'].slideNext();
        });
        ivsPrevEl.addEventListener('click', () => {
          influencerVideoSwiperEl['swiper'].slidePrev();
        });

        Object.assign(influencerVideoSwiperEl, {
          slidesPerView: 1,
          spaceBetween: 10,
          loop: true,
          nextButton: '#influencerVideoSwiper-next',
          prevButton: '#influencerVideoSwiper-prev',
          breakpoints: {
            '420': {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            '576': {
              spaceBetween: 10,
              slidesPerView: 1,
            },
            '768': {
              spaceBetween: 20,
              slidesPerView: 1,
            },
          },
        });
      } else {
        return
      }
    } else{
      this.init = false;
    }
  }

  goToCommercialBannerPage() {
    if (this.platformisWebView) {
      window.open(
        'https://sale.shippn.com/valentinesday/',
        'system',
        'noreferrer'
      );
    } else {
      window.open(
        'https://sale.shippn.com/valentinesday/',
        '_blank',
        'noreferrer'
      );
    }
  }

  isWebview = () => {
    if (typeof window === undefined) {
      return false;
    }
    let navigator: any = window.navigator;
    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios ? !standalone && !safari : userAgent.includes('wv');
  };
  isAndrodiWebview = () => {
    let isWebView = false;
    let userAgent = navigator.userAgent;
    if (/Android/.test(userAgent)) {
      var androidVersion = parseFloat(
        userAgent.slice(userAgent.indexOf('Android') + 8)
      );
      if (androidVersion >= 10) {
        isWebView = /(wv)/.test(userAgent);
      } else {
        isWebView = userAgent.includes('Version/');
      }
    }
    if (isWebView) {
      return true;
    } else {
      return false;
    }
  };
  platformisWebView = false;
  arrayMaker(n) {
    return Array(n);
  }
  copyToBlackFridayDiscountCode() {
    let copiedText = 'FRIDAY30';
    navigator.clipboard
      .writeText(copiedText)
      .then(() => {
        this.toastr.success('Discount code copied to clipboard');
      })
      .catch(() => {
        this.toastr.error('Failed to copy to clipboard');
      });
  }

  openYTVideoDialog(videoData): void {
    const dialogRef = this.dialog.open(YTPlayerDialog, { data: videoData });
  }
  selectedVideo = null;
  selectVideo(video) {
    this.selectedVideo = video;
  }
}
