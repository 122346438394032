import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup.service';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { WatcherService } from 'src/app/services/watcher.service';
import { DOCUMENT } from '@angular/common';
import { TitleAndDescriptionsService } from 'src/app/services/titles-and-descriptions.service';
import { titlesAndDescriptions } from 'src/app/services/titlesAndDescriptions';
import { Store } from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import * as AuthActions from './../../store/shared/auth/auth.actions';
import { StripeService } from 'src/app/services/stripeService.service';
import { SEOService } from 'src/app/services/seo.service';
import { ToastrService } from 'ngx-toastr';

declare var $;
declare var dengage;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  basketVisible = true;
  lang: string = 'en';
  paramLang: string = '';
  selectedConversationID;
  token: string = '';
  isLogin = false;
  showDropdown = true;
  loggedUser;
  userID;
  signUpFromCode = '';
  activeAnnouncementBanner: Boolean = false;
  announcementBannerHeight: Number = 0;
  activeURl = '';
  scrollPositionForMobileMenu = 0;
  isHomePage = true;
  commercialShoutBarSource =
    'https://d1we2hgzmci25y.cloudfront.net/assets/img/special-days-lp/black-friday/black_friday_2024_mobile.webp';
  hideMasterCardArea = false;
  hostCountriesCount = 0;
  klarnaShoutbarImage =
    'assets/img/banners/shoutbar/klarna_split_black_970x90.png';

  isShopNowActive: boolean = false;

  blackFridayPageIsActive: boolean = false;

  @HostListener('window:resize', ['$event.target'])
  onResize() {
    this.resizeWorks();
    if (window.innerWidth > 992) {
      this.closeMobileMenu();
      const mobileMenuElement = this.document.getElementById(
        'shippnMobileMenu'
      ) as HTMLElement;
      if (mobileMenuElement.classList.contains('animate')) {
        mobileMenuElement.classList.remove('animate');
      }
    }
  }
  private resizeWorks(): void {
    let windowSize = window.innerWidth;
    if (windowSize > 768) {
      this.announcementBannerHeight = 0;
      this.commercialShoutBarSource =
        'https://d1we2hgzmci25y.cloudfront.net/assets/img/special-days-lp/black-friday/black_friday_2024_desktop.webp';
      this.klarnaShoutbarImage =
        'assets/img/banners/shoutbar/klarna_split_black_970x90.png';
    } else {
      this.commercialShoutBarSource =
        'https://d1we2hgzmci25y.cloudfront.net/assets/img/special-days-lp/black-friday/black_friday_2024_mobile.webp';
      this.klarnaShoutbarImage =
        'assets/img/banners/shoutbar/klarna_split_black_728x90.png';
    }
  }
  constructor(
    public signUpService: SignupService,
    public router: Router,
    private watcherService: WatcherService,
    @Inject(DOCUMENT) private document: Document,
    private tAdService: TitleAndDescriptionsService,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    protected stripeService: StripeService,
    private seoService: SEOService,
    private toastr: ToastrService
  ) {}
  openNotificationCenter = false;
  notifyMessagesCount;
  notificationMessages;
  hostUnseenMessageBand = false;
  isNotificationCenterAvailable = false;
  shoppingCartItems;
  isthereBfmBasket = false;
  bfmUrlAnonymousId = '';
  bfmUrlCartId = '';
  hostStoreBFMItems = null;
  hostStoreTotalItems = 0;
  hostStoreBFMAnonymousID;
  hostStoreBFMShoppingCartID;

  hello10Variant: boolean = false;

  getCookie(name: string): string | undefined {
    const cookieString = this.document.cookie;
    if (cookieString.length > 0) {
      const cookieArray = cookieString.split(';');
      for (const cookie of cookieArray) {
        const [cookieName, cookieValue] = cookie.trim().split('=');
        if (cookieName === name) {
          return cookieValue;
        }
      }
    }
    return '';
  }
  isAffiliate = false;
  hostCountries: any[] = [];
  ngOnInit(): void {
    this.seoService.createHrefLangs();
    this.watcherService.getHostCountriesResponse().subscribe({
      next: (list) => {
        if (list['data']) {
          this.hostCountries = list['data'];
          this.hostCountriesCount = this.hostCountries.length;
          const countriesVIP = [
            'United States of America',
            'United Kingdom',
            'Australia',
            'Canada',
            'Germany',
            'Japan',
            'France',
            'Netherlands',
            'Italy',
            'Sweden',
          ];

          this.hostCountries = list['data']
            .filter((country) => countriesVIP.includes(country.name))
            .map((country) => ({
              ...country,
              code: country.code.toLowerCase(),
            }))
            .sort((a, b) => {
              const indexA = countriesVIP.indexOf(a.name);
              const indexB = countriesVIP.indexOf(b.name);
              return indexA - indexB;
            });
        }
      },
      error: (err) => {
        this.toastr.error(err.error.message, 'Error');
      },
    });
    this.store.select('auth').subscribe((state) => {
      this.token = state.token;
      this.isLogin = state.isAuthenticated;
      this.loggedUser = state.user;
      this.userID = state.user?.id;

      if (this.userID) {
        if (
          this.userID === '64ad4904fd26fa274c2f5b5d' ||
          this.userID === '64db269bfd26fa0d0c51a17d'
        ) {
          this.isAffiliate = true;
        }
      }

      if (state.isAuthenticated) {
        if (this.loggedUser.type !== 1) {
          this.basketVisible = false;
        }
      } else {
        this.basketVisible = true;
      }

      dengage('setContactKey', this.userID);
    });
    this.watcherService.getLang().subscribe((lang) => {
      if (!lang || lang === undefined || lang === null || lang === '') {
        lang = 'en';
        this.lang = lang;
        // this.translateTo(lang)
      } else {
        this.lang = lang;
        // this.translateTo(lang)
      }
    });
    this.watcherService.onHeaderSignUpCodeUpdate.subscribe((code) => {
      code ? (this.signUpFromCode = code) : (this.signUpFromCode = '');
    });
    this.watcherService.onAnnouncementBannerStatusUpdate.subscribe((status) => {
      status
        ? (this.activeAnnouncementBanner = status)
        : (this.activeAnnouncementBanner = false);
    });
    this.watcherService.onAnnouncementBannerHeightUpdate.subscribe((height) => {
      height
        ? (this.announcementBannerHeight = height)
        : (this.announcementBannerHeight = 0);
    });

    this.hostStoreBFMAnonymousID = localStorage.getItem('anonymous_id');
    this.hostStoreBFMShoppingCartID = localStorage.getItem('cart_id');

    if (
      this.hostStoreBFMAnonymousID &&
      this.hostStoreBFMShoppingCartID &&
      this.token
    ) {
      this.signUpService
        .getBFMDataFromCartID(
          this.hostStoreBFMShoppingCartID,
          this.hostStoreBFMAnonymousID
        )
        .subscribe((data) => {
          if (data['status'] === 'OPEN') {
            data['items']
              ? (this.hostStoreBFMItems = data['items'])
              : (this.hostStoreBFMItems = null);
            data['anonymous_id']
              ? (this.hostStoreBFMAnonymousID = data['anonymous_id'])
              : (this.hostStoreBFMAnonymousID = null);
            data['shopping_cart_id']
              ? (this.hostStoreBFMShoppingCartID = data['shopping_cart_id'])
              : (this.hostStoreBFMShoppingCartID = null);

            if (this.hostStoreBFMItems && this.hostStoreBFMItems.length > 0) {
              for (var i = 0; i < this.hostStoreBFMItems.length; ++i) {
                this.hostStoreTotalItems =
                  this.hostStoreTotalItems + this.hostStoreBFMItems[i].quantity;
              }
            }
          } else {
            localStorage.removeItem('anonymous_id');
            localStorage.removeItem('cart_id');
          }
        });
    }

    this.store.select('shoppingCart').subscribe((state) => {
      if (state.status !== 'CLOSED') {
        this.shoppingCartItems = state.bfm_items;
        if (state.anonymous_id !== '') {
          this.isthereBfmBasket = true;
          this.bfmUrlAnonymousId = state.anonymous_id;
          this.bfmUrlCartId = state.shopping_cart_id;
        }
      } else {
        this.isthereBfmBasket = false;
        this.shoppingCartItems = [];
      }
    });
    this.onResize();
    this.router.events.forEach((ev) => {
      this.seoService.createMapsScript();
      if (ev instanceof NavigationStart) {
        this.activeURl = ev.url;

        if (!this.activeURl.includes('home') && this.activeURl !== '/') {
          this.stripeService.createScript();
          this.seoService.createMapsScript();
          this.seoService.removeReviewsSchema();
        } else {
          this.seoService.createReviewsSchema();
        }
      }
      if (ev instanceof NavigationEnd) {
        $('.modal').modal('hide');
        this.activeURl = ev.url;
        if (
          this.activeURl.includes('c/black-friday') ||
          this.activeURl.includes('d/black-friday')
        ) {
          this.blackFridayPageIsActive = true;
        } else {
          this.blackFridayPageIsActive = false;
        }

        if (this.activeURl.includes('bfm/latest-purchases/')) {
          this.seoService.createMetaNoIndex();
        } else {
          this.seoService.removeMetaNoIndex();
        }
        if (this.activeURl.includes('hi10')) {
          this.hello10Variant = true;
        } else {
          this.hello10Variant = false;
        }
        this.seoService.createCanonicalUrl();
        this.seoService.createHrefLangs();
        if (!this.activeURl.includes('home') && this.activeURl !== '/') {
          this.isHomePage = false;
          this.seoService.removeReviewsSchema();
          this.seoService.removeOrganizationSchema();
        } else {
          this.isHomePage = true;
          this.seoService.createReviewsSchema();
          this.seoService.createOrganizationSchema();
        }
        if (!this.activeURl.includes('shopping')) {
          window.scrollTo(0, 0);
        }

        if (this.activeURl.includes('become-a-host')) {
          this.hideMasterCardArea = true;
        } else {
          this.hideMasterCardArea = false;
        }
        let urlParts = this.activeURl.split('/');
        let cookieLang = this.getCookie('googtrans');
        let activeTranslatedLang = cookieLang.slice(4, cookieLang.length);

        if (
          activeTranslatedLang !== '' &&
          this.activeURl != '/ship-to-united-states'
        ) {
          if (urlParts[1] !== activeTranslatedLang && urlParts.length >= 3) {
            if (urlParts[1].length > 2) {
              urlParts.splice(1, 0, activeTranslatedLang);
            } else {
              urlParts[1] = activeTranslatedLang;
            }
            let newUrl = urlParts.join('/');
            this.router.navigateByUrl(newUrl);
          } else if (
            urlParts[1] !== activeTranslatedLang &&
            urlParts.length === 2
          ) {
            if (urlParts[1].length > 2) {
              urlParts.splice(1, 0, activeTranslatedLang);
            } else {
              urlParts[1] = activeTranslatedLang;
            }
            if (urlParts[2] === '') {
              urlParts.push('home');
            }

            let newUrl = urlParts.join('/');
            this.router.navigateByUrl(newUrl);
          }
        } else {
          if (this.activeURl !== '/ship-to-united-states') {
            this.translateTo(urlParts[1]);
          }
        }
        let newPage = titlesAndDescriptions.find((el) =>
          ev.url.includes(el.route)
        );

        if (
          newPage !== null &&
          newPage !== undefined &&
          newPage.route != 'getaddress-host'
        ) {
          this.tAdService.updateWebPageTitle(newPage.title);
          this.tAdService.updateWebsiteDescription(newPage.description);

          if (newPage.route === '/home') {
            this.tAdService.updateWebsiteDescription(
              `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep!`
            );
          }
        } else {
          if(ev.url.includes('shop-and-ship/from')) {
          } else{
            this.tAdService.updateWebPageTitle(
              'Worldwide Shipping Service | Shop Anywhere, Ship Everywhere - Shippn'
            );
            this.tAdService.updateWebsiteDescription(
              `Shop online from more than ${this.hostCountriesCount} countries and get it delivered to your doorstep!`
            );
          }
          
        }

        if (this.loggedUser && !ev.url.includes('signin') && this.loggedUser.type !== 5) {
          this.signUpService
            .getAllMesssagesForUser(this.token)
            .subscribe((res) => {
              this.notificationMessages = res['data'].filter((msg) => {
                return msg.msg_obj.seen === 0;
              });
              this.notifyMessagesCount = 0;
              this.watcherService.setMessagesNotificationsData(
                this.notificationMessages
              );
              if (this.loggedUser && this.loggedUser.type === 1) {
                this.notificationMessages = res['data'].filter((msg) => {
                  return msg.msg_obj.seen === 0;
                });
                for (
                  let index = 0;
                  index < this.notificationMessages.length;
                  index++
                ) {
                  if (
                    this.notificationMessages[index].message.seen_by_shopper ===
                    0
                  ) {
                    this.notifyMessagesCount +=
                      this.notificationMessages[index].msg_obj.count;
                  }
                }
              } else if (this.loggedUser && this.loggedUser.type === 2) {
                this.notificationMessages = res['data'].filter((msg) => {
                  return msg.msg_obj.seen === 0;
                });
                this.hostUnseenMessageBand = this.notificationMessages.some(
                  (a) => a.seen_by_host === 0
                );
                for (
                  let index = 0;
                  index < this.notificationMessages.length;
                  index++
                ) {
                  if (
                    this.notificationMessages[index].message.seen_by_host === 0
                  ) {
                    this.notifyMessagesCount +=
                      this.notificationMessages[index].msg_obj.count;
                  }
                }
              } else {
              }
              this.isNotificationCenterAvailable = true;
            });
        }
      }
    });
  }
  openNotificationcenterArea() {
    if (this.isNotificationCenterAvailable === true)
      return (this.openNotificationCenter = true);
  }
  autoLogout(logoutTime) {
    setTimeout(() => {
      this.logout();
    }, logoutTime);
  }
  logout() {
    this.notifyMessagesCount = 0;
    this.store.dispatch(AuthActions.Logout());
    localStorage.removeItem('token');
    localStorage.removeItem('is_email_verified');
    localStorage.removeItem('userId');
    localStorage.removeItem('is_phone_verified');
    localStorage.removeItem('confrimedCheckout');
    localStorage.removeItem('paypal_verified');
    localStorage.removeItem('CACHED_USER');
    let sessionUserId = this.userID;
    let lsOrderUserID = localStorage.getItem('orderDetailUserId');
    if (sessionUserId === lsOrderUserID) {
      localStorage.removeItem('orderDetailID');
      localStorage.removeItem('orderDetailUserId');
    }
    let lsBFMUserId = localStorage.getItem('bfm_shopper_id');
    if (sessionUserId === lsBFMUserId) {
      localStorage.removeItem('anonymous_id');
      localStorage.removeItem('cart_id');
      localStorage.removeItem('bfm_country_from');
      localStorage.removeItem('bfm_country_to');
      localStorage.removeItem('bfm_shopper_id');
    }

    this.router.navigate(['/', this.lang, 'signin']);
  }
  goDashboard() {
    if (this.loggedUser.type === 1) {
      this.router.navigate(['/', this.lang, 'member', 'packs-expected']);
    }
    if (this.loggedUser.type === 5) {
      this.router.navigate(['/', this.lang, 'b2b', 'create-shipment']);
    } else if (this.loggedUser.type === 2 && !this.isAffiliate) {
      this.router.navigate(['/', this.lang, 'member', 'host-packs-expected']);
    }

    if (this.isAffiliate) {
      this.router.navigate(['/', this.lang, 'member', 'host-store']);
    }
    if (this.loggedUser.type === 4) {
      this.router.navigate(['/', this.lang, 'member', 'affiliate-dashboard']);
    }
    this.closeMobileMenu();
  }
  goURL() {
    window.open(
      'https://sale.shippn.com/cyber-monday-tech-deals/',
      '_blank',
      ''
    );
  }
  translateTo(lang) {
    let langModal = window.document.getElementById('translateLanguageCloser');
    let language = lang;
    if (language === 'impossible') {
      this.document.documentElement.lang = language;
      var iframe: any = document.getElementsByClassName(
        'goog-te-banner-frame'
      )[0];
      if (langModal) {
        langModal.click();
      }
      if (!iframe) return;
      var innerDoc: any =
        iframe.contentDocument || iframe.contentWindow.document;
      var restore_el = innerDoc.getElementsByTagName('button');
      for (var i = 0; i < restore_el.length; i++) {
        if (restore_el[i].id.indexOf('restore') >= 0) {
          restore_el[i].click();
          var close_el = innerDoc.getElementsByClassName('goog-close-link');
          close_el[0].click();
          return;
        }
      }
    } else {
      setTimeout(() => {
        var selectField: any = document.querySelector(
          '#google_translate_element select'
        );
        if (selectField) {
          for (var i = 0; i < selectField.children.length; i++) {
            var option: any = selectField.children[i];
            if (option.value === language) {
              selectField.selectedIndex = i;
              selectField.dispatchEvent(new Event('change'));
              this.document.documentElement.lang = language;
              break;
            }
          }
          this.watcherService.setLang(lang);
          const currentUrl = this.activeURl;
          const urlParts = currentUrl.split('/');
          if (urlParts[1] !== lang && urlParts.length >= 3) {
            urlParts[1] = lang;
            let newUrl = urlParts.join('/');
            this.router.navigateByUrl(newUrl);
          } else if (urlParts[1] !== lang && urlParts.length === 2) {
            urlParts[1] = lang;
            urlParts[2] = 'home';
            let newUrl = urlParts.join('/');
            // this.router.navigateByUrl(newUrl);
          }
        }
      }, 500);
    }
    if (langModal) {
      langModal.click();
    }
  }
  openBlog() {
    window.open('https://www.shippn.com/blog', '_blank', 'noopener,noreferrer');
  }

  isMenuOpen = null;
  mobileMenuDynamicHeight = 0;
  toggleMobileMenu() {
    this.scrollPositionForMobileMenu = 116.15 - window.scrollY;
    this.mobileMenuDynamicHeight =
      window.innerHeight - this.scrollPositionForMobileMenu;
    this.isMenuOpen = !this.isMenuOpen;
    const mobileMenuElement = this.document.getElementById(
      'shippnMobileMenu'
    ) as HTMLElement;
    if (mobileMenuElement.classList.contains('animate')) {
    } else {
      mobileMenuElement.classList.add('animate');
    }

    const bodyElement = this.document.body as HTMLElement;
    if (bodyElement.classList.contains('overflow-hidden-menu-opened')) {
      bodyElement.classList.remove('overflow-hidden-menu-opened');
      this.closeAllDropDowns();
    } else {
      bodyElement.classList.add('overflow-hidden-menu-opened');
    }
  }

  closeMobileMenu() {
    if (this.isMenuOpen == true) {
      this.isMenuOpen = false;
      const bodyElement = this.document.body as HTMLElement;
      if (bodyElement.classList.contains('overflow-hidden-menu-opened')) {
        bodyElement.classList.remove('overflow-hidden-menu-opened');
        this.closeAllDropDowns();
      }
    } else {
      return;
    }
  }
  headerDrops = {
    locationsDropdown: false,
    helpDropDown: false,
    becomeAPartnerDropdown: false,
    profileDropdown: false,
  };

  toggleMobileDropDown(dropdownElementId) {
    const dropdownListsElement = this.document.querySelectorAll(
      '.shippn-mobile-menu-list-dropdown'
    );
    dropdownListsElement.forEach((it) => {
      if (it.id != dropdownElementId) {
        if (it.classList.contains('show')) {
          it.classList.remove('show');
        }
      }
    });
    const dropdownElement = this.document.getElementById(
      dropdownElementId
    ) as HTMLElement;
    dropdownElement.classList.toggle('show');
  }

  closeAllDropDowns() {
    const dropdownElements = this.document.getElementsByClassName(
      'shippn-mobile-menu-list-dropdown'
    ) as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < dropdownElements.length; i++) {
      if (dropdownElements[i].classList.contains('show')) {
        dropdownElements[i].classList.remove('show');
      }
    }
  }

  goProfileOrSignIn() {
    if (this.loggedUser) {
      this.router.navigate(['/', this.lang, 'settings', 'profile']);
    } else {
      this.router.navigate(['/', this.lang, 'signin']);
    }
  }

  isWebview = () => {
    if (typeof window === undefined) {
      return false;
    }
    let navigator: any = window.navigator;
    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios ? !standalone && !safari : userAgent.includes('wv');
  };
  isAndrodiWebview = () => {
    let isWebView = false;
    let userAgent = navigator.userAgent;
    if (/Android/.test(userAgent)) {
      var androidVersion = parseFloat(
        userAgent.slice(userAgent.indexOf('Android') + 8)
      );
      if (androidVersion >= 10) {
        isWebView = /(wv)/.test(userAgent);
      } else {
        isWebView = userAgent.includes('Version/');
      }
    }
    if (isWebView) {
      return true;
    } else {
      return false;
    }
  };

  platformisWebView = false;

  goToCommercialBannerPage() {
    if (this.platformisWebView) {
      window.open(
        'https://sale.shippn.com/valentinesday/',
        'system',
        'noreferrer'
      );
    } else {
      window.open(
        'https://sale.shippn.com/valentinesday/',
        '_blank',
        'noreferrer'
      );
    }
  }

  copyToBlackFridayDiscountCode() {
    let copiedText = 'FRIDAY30';
    navigator.clipboard
      .writeText(copiedText)
      .then(() => {
        this.toastr.success('Discount code copied to clipboard');
      })
      .catch(() => {
        this.toastr.error('Failed to copy to clipboard');
      });
  }
}
