import { Component, OnDestroy, OnInit } from "@angular/core";
import { WatcherService } from "src/app/services/watcher.service";

@Component({
    selector: 'home-banner-animated-html',
    templateUrl: './home-banner-animated-html.component.html',
    styleUrls: ['./home-banner-animated-html.component.css']
})
export class HomePageBannerAnimatedComponent implements OnInit {
    lang: string = 'en';
    constructor(
        private watcherService: WatcherService,
    ){}

    ngOnInit(): void {
        this.watcherService.getLang().subscribe((lang: string) => {
            this.lang = lang;
        })
    }
}