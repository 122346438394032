import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SignupService } from './services/signup.service';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomepageComponent } from './components/homepage/homepage.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { FacebookModule } from 'ngx-facebook';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { CustomHttpInterceptor } from './custom-http.interceptor';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { CacheInterceptor } from './services/cache-interceptor';
import { CacheResolverService } from './services/cache-resolver.service';
import { CountdownBarComponent } from './components/countdown-bar/countdown-bar.component';
import { DefaultUrlSerializer, Router, UrlSerializer, UrlTree } from '@angular/router';
import { NotificationCenterComponent } from './shared/features/notification-center/notification-center.component';
import { AmIVisibleDirective } from './directives/am-ivisible.directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialAuthServiceConfig, SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { StoreModule } from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReudcerLocalStorage } from './modules/shopping/store/shopping.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ShoppingEffects } from './modules/shopping/store/shopping.effects';
import { environment } from '../environments/environment';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import * as firebase from 'firebase/app';
import { GlobalErrorHandler } from './services/globalerror.service';
import { StickyActionBarComponent } from './layouts/sticky-action-bar/sticky-action-bar.component';
import * as Sentry from "@sentry/angular";

import { register } from 'swiper/element/bundle';
register();
import {
  HAMMER_GESTURE_CONFIG,
  HammerModule
} from '@angular/platform-browser';
import {
  LyTheme2,
  StyleRenderer,
  LY_THEME,
  LY_THEME_NAME,
  LyHammerGestureConfig
} from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { IMAGE_LOADER, ImageLoaderConfig, NgOptimizedImage } from '@angular/common';
import { InstantCalculatorDiscountCountdownComponent } from './components/instant-calculator-discount-countdown/instant-calculator-discount-countdown.component';
import { Hi10Component } from './components/hi10/hi10';
import { Homepage2Component } from './components/homepage2/homepage2.component';
import { LazyLoadDirective } from './directives/lazy-load/lazy-load.directive';
import { Homepage3Component } from './components/homepage3/homepage3.component';
import { ExchangeRateService } from './services/exchange-rate.service';
import { SharedModule } from './modules/shared/shared.module';
import { HomepageVidComponent } from './components/homepage-vid/homepage-vid.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RefundShipmentDialogComponent } from './components/b2b/b2b-api-user/refund-shipment-dialog/refund-shipment-dialog.component';
import { FlagsComponent } from './components/flags/flags.component';
import { HomePageBannerAnimatedComponent } from './features/VWO/Tests/home-banner-animated-html/home-banner-animated-html.component';
firebase.initializeApp(environment.firebase);
export class LowerCaseURLSerializer extends DefaultUrlSerializer{
  parse(url: string): UrlTree {
    
    if (url.includes('shop-from/') && !url.includes('getaddress-details')){
      return super.parse(url.toLowerCase());

    } else{
      return super.parse(url);
    }


    
  }
}
const ngxUiLoaderConfig: NgxUiLoaderConfig =
{
  fgsColor: "#ff6c36",
  bgsColor: "#ff6c36",
  pbColor: "#ff6c36",
  blur: 10,
  overlayColor: "rgba(255,255,255,0.55)",
  delay: 0,
  masterLoaderId: "master"
}
@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    Homepage2Component,
    Homepage3Component,
    HomepageVidComponent,
    HomePageBannerAnimatedComponent,
    HeaderComponent,
    FooterComponent,
    CountdownBarComponent,
    NotificationCenterComponent,
    AmIVisibleDirective, 
    StickyActionBarComponent, 
    InstantCalculatorDiscountCountdownComponent,
    Hi10Component,
    LazyLoadDirective,
    RefundShipmentDialogComponent,
    FlagsComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgOptimizedImage,
    LyButtonModule,
    LyToolbarModule,
    LyImageCropperModule,
    HammerModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    FacebookModule.forRoot(),
    GoogleTagManagerModule.forRoot({
      id: "GTM-P3PN6C8",
    }),
    NgxGoogleAnalyticsModule.forRoot('G-BEDQ89S6B0'),
    NgxGoogleAnalyticsRouterModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    StoreModule,
    StoreModule.forRoot(fromApp.appReducer, {metaReducers: [metaReudcerLocalStorage]}),
    EffectsModule.forRoot([ShoppingEffects]),
    StoreDevtoolsModule.instrument({logOnly: environment.production}),
    MatDialogModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseURLSerializer
    },
    CacheResolverService,
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `https://d1we2hgzmci25y.cloudfront.net/assets/img/${config.src}`
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '521811933540-2e1mia62hpqrldubpupk3v72ihekjuhr'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: FIREBASE_OPTIONS, useValue: environment.firebase
    },
    SocialAuthService,
    SignupService,
    ExchangeRateService,
    {
      provide: ErrorHandler, useClass: GlobalErrorHandler
    },
    [ LyTheme2 ],
    [ StyleRenderer ],
    // Theme that will be applied to this module
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
    { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
    // Gestures
    { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    NotificationCenterComponent,
    AmIVisibleDirective
  ]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}