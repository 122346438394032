import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SignupService } from './services/signup.service';
import { WatcherService } from './services/watcher.service';
import { Store, select } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import { Observable, filter } from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { SEOService } from './services/seo.service';
import { InstantCalculatorDisocuntCountdownService } from './services/instant-calculator-disocunt-countdown.service';
declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  remainingTime: { minutes: number; seconds: number };
  timerStart: boolean = false;
  timerFinished: boolean = false;
  title = 'shippn';
  token;
  previousUrl;  
  isUserLoggedIn: Boolean = false;

  headerWithoutMastercardHeight;
  isShopNowActive: boolean = false;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: PlatformLocation,
    public router: Router,
    private signUpService: SignupService,
    private watcherService: WatcherService,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    private seoService: SEOService,
    private _icdcService: InstantCalculatorDisocuntCountdownService
  ) {
    const navEndsEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );

    navEndsEvents.subscribe((event: NavigationEnd) => {
      const gtmTag = {
        event: 'page',
        pageName: event.url,
        data: { pageView: event.url },
      };
      this.gtmService.pushTag(gtmTag);
      if (
        event.url.includes(`${this.lang}/shopnow`) ||
        event.url.includes(`${this.lang}/shop-now`)
      ) {
        this.isShopNowActive = true;
      } else {
        this.isShopNowActive = false;
      }
    });
  }
 
  selectedConversationID;
  activeRoute;
  lang;

  isWebview = () => {
    if (typeof window === undefined) {
      return false;
    }
    let navigator: any = window.navigator;
    const standalone = navigator?.standalone;
    const userAgent = navigator?.userAgent?.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios ? !standalone && !safari : userAgent.includes('wv');
  };
  isAndrodiWebview = () => {
    let isWebView = false;
    let userAgent = navigator.userAgent;
    if (/Android/.test(userAgent)) {
      var androidVersion = parseFloat(
        userAgent.slice(userAgent.indexOf('Android') + 8)
      );
      if (androidVersion >= 10) {
        isWebView = /(wv)/.test(userAgent);
      } else {
        isWebView = userAgent.includes('Version/');
      }
    }
    if (isWebView) {
      return true;
    } else {
      return false;
    }
  };

  platformisWebView = false;
  hostCountriesCount = 0;
  private supportedLanguages = [
    'en',
    'zh-CN',
    'iw',
    'es',
    'fr',
    'ar',
    'de',
    'ru',
    'pt',
    'id',
    'it',
    'ja',
    'ms',
    'nl',
    'sv',
    'no',
  ];
  private defaultLanguage = 'en';
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = event.url.split('?')[0];
        const queryParams = event.url.split('?')[1]
          ? '?' + event.url.split('?')[1]
          : '';

        const langSegment = url.split('/')[1];

        if (!this.supportedLanguages.includes(langSegment)) {
          const newUrl = `/${this.defaultLanguage}${url}`;
          this.router.navigateByUrl(newUrl + queryParams);
        } else {
          this.watcherService.setLang(langSegment);
        }
      }
    });

    this._icdcService.getRemainingTime().subscribe((time) => {
      this.remainingTime = time;
    });
    this._icdcService.getTimerStartSignal().subscribe((start) => {
      this.timerStart = start;
    });

    this._icdcService.getTimerFinishedSignal().subscribe((finished) => {
      this.timerFinished = finished;
    });

    if (this.isAndrodiWebview() || this.isWebview()) {
      this.platformisWebView = true;
      const externalLink = window.location.href;
      if (externalLink.includes('affiliate_id')) {
        let trimmedLink = externalLink.replace(
          'https://www.shippn.com/en/affiliate/product/',
          ''
        );
        let productIdWithparam = trimmedLink.split('product/')[1];
        let productId = productIdWithparam.split('?')[0];
        let affiliateIDwithParam = productIdWithparam.split('?')[1];
        let affiliateID = affiliateIDwithParam.replace('affiliate_id=', '');
        this.router.navigate(
          ['/', this.lang, 'affiliate', 'product', productId],
          {
            queryParams: {
              affiliate_id: affiliateID,
            },
          }
        );
      }
    }

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('become-a-host')) {
          this.headerWithoutMastercardHeight = '61.14px';
        } else {
          this.headerWithoutMastercardHeight = '116.14px';
        }
      }
    });

    this.seoService.createMapsScript();
    this.location.onPopState(() => {
      $('.modal').modal('hide');
    });
    this.store.select('auth').subscribe((state) => {
      this.token = state.token;
      this.isUserLoggedIn = state.isAuthenticated;
      this.userID = state.user?.id;
    });
    this.signUpService.getHostCountries().subscribe({
      next: (list) => {
        this.watcherService.setHostCountriesResponse(list);
        let modifiedList = list['data'].map((country) => {
          return { ...country, code: country?.code };
        });
        this.hostCountries = modifiedList;
        this.hostCountriesCount = this.hostCountries.length;
        localStorage.setItem(
          'hostCountriesCount',
          String(this.hostCountriesCount)
        );
        this.countryFrom = list['data'][0]['code'].toLowerCase();
        this.getNewAdressCountryFrom = this.hostCountries.find((country) => {
          return country.code.toUpperCase() == this.countryFrom.toUpperCase();
        });
      },
      error: (err) => {
        
      }
    });
    this.watcherService.getLang().subscribe((lang) => {
      this.lang = lang;
      this.cdr.detectChanges();
    });
    // this.changeBFMPopUpItem();
  }
  isUserLogin;
  cachedUser;
  loggedInUserType;
  userID;
  hostCountries = [];
  countryFrom = 'AU';
  getNewAdressCountryFrom;

  getNewAddressCountry(countryCode) {
    this.countryFrom = countryCode;
    this.getNewAdressCountryFrom = this.hostCountries.find((country) => {
      return country.code.toUpperCase() == countryCode.toUpperCase();
    });
  }
  closeShopFromModal() {
    $('#getNewAdressModal').modal('hide');
  }
  orderCreateForm = new UntypedFormGroup({
    shopperName: new UntypedFormControl(''),
    orderNumber: new UntypedFormControl(''),
  });
  searchAndCreateOrder() {
    var userData = {
      order_number: this.orderCreateForm.value.orderNumber,
      shopper_name: this.orderCreateForm.value.shopperName,
      shopper_id: '',
    };
    this.signUpService
      .hostCreateOrder(this.token, userData, this.userID)
      .subscribe((data) => {
        if (data['result']['status'] == 200) {
          this.toastr.success(data['result']['message'], 'Success');
          $('#createOrderModal').modal('hide');
          this.orderCreateForm.reset();
        } else {
          this.toastr.error(data['result']['message'], 'Error');
        }
      });
  }
}
