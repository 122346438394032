import {
  AfterViewInit,
  Component,
  ElementRef,
  Renderer2,
  OnDestroy,
  Input,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-trustpilot-widget',
  templateUrl: './trustpilot-widget.component.html',
  styleUrls: ['./trustpilot-widget.component.css'],
})
export class TrustpilotWidgetComponent implements AfterViewInit, OnDestroy {
  @Input() trustpilotWidgetType: 'desktop' | 'mobile' | 'both' = 'both';

  private desktopTemplateIdKey = '5419b6ffb0d04a076446a9af';
  private mobileTemplateIdKey = '53aa8807dec7e10d38f59f32';

  private widgetDesktopHeightVal = '20px';
  private widgetMobileHeightVal = '130px';

  public widgetDesktopHeight = '20px';
  public widgetMobileHeight = '130px';

  public desktopTemplateId = this.desktopTemplateIdKey;
  public mobileTemplateId = this.mobileTemplateIdKey;

  private routerSubscription!: Subscription;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private router: Router
  ) {}

  ngAfterViewInit(): void {
    // this.decideTemplate();
    // this.loadTrustpilotScript();

    // this.routerSubscription = this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     this.reloadTrustpilotWidgets();
    //   });
  }

  private decideTemplate() {
    if (this.trustpilotWidgetType === 'desktop') {
      this.mobileTemplateId = this.desktopTemplateIdKey;
      this.widgetDesktopHeight = '20px';
      this.widgetMobileHeight = '20px';
    } else if (this.trustpilotWidgetType === 'mobile') {
      this.desktopTemplateId = this.mobileTemplateIdKey;

      this.widgetDesktopHeight = '130px';
      this.widgetMobileHeight =  '130px'
    } else {
      this.desktopTemplateId = this.desktopTemplateIdKey;
      this.mobileTemplateId = this.mobileTemplateIdKey;
      this.widgetDesktopHeight = '20px';
      this.widgetMobileHeight = '130px';
    }
  }

  private loadTrustpilotScript(): void {
    const script = this.renderer.createElement('script');
    script.src =
      '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    script.async = true;

    script.onload = () => {
      this.reloadTrustpilotWidgets();
    };

    script.onerror = () => {
    };

    this.renderer.appendChild(this.elRef.nativeElement, script);
  }

  private reloadTrustpilotWidgets(): void {
    this.decideTemplate();
    const desktopWidget = this.elRef.nativeElement.querySelector(
      '.trustpilot-widget.desktop'
    );
    const mobileWidget = this.elRef.nativeElement.querySelector(
      '.trustpilot-widget.mobile'
    );

    if (desktopWidget) {
      this.renderer.setAttribute(desktopWidget, 'data-locale', 'en-US');
      this.renderer.setAttribute(
        desktopWidget,
        'data-template-id',
        this.desktopTemplateId
      );
      this.renderer.setAttribute(
        desktopWidget,
        'data-businessunit-id',
        '5911d3700000ff0005a2206a'
      );
      this.renderer.setAttribute(desktopWidget, 'data-style-height', this.widgetDesktopHeight);
      this.renderer.setAttribute(desktopWidget, 'data-style-width', '100%');

      if ((window as any).Trustpilot) {
        (window as any).Trustpilot.loadFromElement(desktopWidget);
      } else {
      }
    }

    if (mobileWidget) {
      this.renderer.setAttribute(mobileWidget, 'data-locale', 'en-US');
      this.renderer.setAttribute(
        mobileWidget,
        'data-template-id',
        this.mobileTemplateId
      );
      this.renderer.setAttribute(
        mobileWidget,
        'data-businessunit-id',
        '5911d3700000ff0005a2206a'
      );
      this.renderer.setAttribute(mobileWidget, 'data-style-height', this.widgetMobileHeight);
      this.renderer.setAttribute(mobileWidget, 'data-style-width', '100%');

      if ((window as any).Trustpilot) {
        (window as any).Trustpilot.loadFromElement(mobileWidget);
      } else {
      }
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
