import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SignupService } from 'src/app/services/signup.service';

@Component({
  selector: 'dialog-yt-player-dialog',
  templateUrl: 'yt-player-dialog.html',
  styleUrls: ['yt-player-dialog.css'],
})
export class YTPlayerDialog implements OnDestroy, OnInit {

  safeVideoUrl: SafeResourceUrl;
  constructor(
    private signUpService: SignupService,
    public dialogRef: MatDialogRef<YTPlayerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video_url);
  }
  close(): void {
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
  }
}
