<div class="pickup-details-dialog-header">
  <button
    (click)="close()"
    style="
      position: absolute;
      top: -18px;
      right: -18px;
      background-color: #fff;
      border: 1px solid #f4f4f4;
      border-radius: 100%;
      min-width: 36px;
      min-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
    "
  >
    <i class="fas fa-times" style="font-size: 24px; color: red"></i>
  </button>
</div>

<div mat-dialog-content class="yt-player-dialog-content">
  <div class="container" style="padding:0;">
    <div style="margin-top: 20px" class="row">
      <div class="col-md-12">
        <div
          class="embed-responsive embed-responsive-16by9 yt-video-player-dialog"
        >
        <iframe width="100%" height="auto" [src]="safeVideoUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>