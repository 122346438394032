<div style="background-color: #E6E6E6;">
    <div class="container shippn-homepage-container">
        <div class="row">
            <div style="margin-top: 80px;margin-bottom: 80px;text-align: center;" class="col-md-12">
                <div style="box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border: none;display: inline-grid;width: 70%;align-items: center;"
                    class="card">
                    <div class="card-body">
                        <div class="row">
                            <div style="text-align: center;" class="col-md-12">
                                <h3 style="font-size: 78px;font-weight: bold;margin-bottom: 0px;color: #f67d48;">404</h3>
                                <h1 style="font-size: 27px;font-weight: bold;margin-bottom: 0px;color: #f67d48;">Not Found
                                </h1>
                                <p>
                                    shippn:thispageisnotavailable
                                </p>
                            </div>
                            <div style="text-align: center;margin-top: 20px;" class="col-md-12">
                                <img class="not-found-image" src="../../../assets/img/404error.webp" alt="404 image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>