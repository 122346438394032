import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';



let env;
let traceSampleRate = 1.0;
if (
  document.location.hostname === 'shippn.com' ||
  document.location.hostname === 'www.shippn.com'
) {
  env = 'prod';
  traceSampleRate = 0.1;
} else if (document.location.hostname === 'preprodv2.shippn.com') {
  env = 'staging';
  traceSampleRate = 0;
} else if (document.location.hostname === 'localhost') {
  env = 'local';
  traceSampleRate = 0;
} else {
  env = 'staging';
  traceSampleRate = 0;
}

Sentry.init({
  dsn: `https://3bb446c883bab5956405d0c558f56292@o4504530895765504.ingest.us.sentry.io/4507900314386432`,
  integrations: [Sentry.browserTracingIntegration({})],
  tracesSampleRate: traceSampleRate,
  tracePropagationTargets: ['localhost', /^\//],
  ignoreErrors: [
    "InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'",
    'Timeout',
    'RangeError',
    "Cannot read properties of null (reading 'toLowerCase')",
    'NG02100',
    /UnknownError/i,
    /Http failure response .*: 0 Unknown Error/,
    /Http failure response .*: 401 OK/,
    'Uncaught (in promise): [object Undefined]',
    'AbortError: The operation was aborted',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    /Cannot read properties of undefined (reading 'includes')/i,
    /reCAPTCHA/i,
    /captchaElem/i,
  ],
  environment: env,
  beforeSend(event, hint) {
    const exception = hint?.originalException || "";
    const requestUrl = event?.request?.url || "";
    const responseStatusCode = event.contexts?.response?.status_code || event.extra?.status_code;
    const errorMessage = event.message || "";

    const allowedDomains = ['localhost', 'preprodv2.shippn.com', 'www.shippn.com', 'shippn.com'];
    if (requestUrl) {
      const url = new URL(event.request.url);
      if (!allowedDomains.includes(url.hostname)) {
        return null;
      }
    }

    if (exception instanceof Error) {
      if (
        exception.message.includes('Timeout') ||
        exception.message.includes("InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'") ||
        exception.message.includes('401') ||
        exception.message.includes('409') ||
        exception.message.includes('404') ||
        exception.message.includes('FirebaseError') || 
        exception.message.includes('NG02100') ||
        exception.message.includes("Can't find variable: FB init") ||
        exception.message.includes('429')
      ) {
        return null;
      }
    } else if (typeof exception === 'object' && exception !== null) {
      const message = (exception as any).message;
      if (message && (
        message.includes('Timeout') ||
        message.includes("InvalidStateError: Failed to set the 'value' property on 'HTMLInputElement'")
      )) {
        return null;
      }
    }
    if (
      responseStatusCode === 401 ||
      (errorMessage.includes("Http failure response for") && requestUrl.includes("https://api.shippn.com/api/message-notifications"))
    ) {
      return null;
    }
    return event;
  },
});
let cachedUser = JSON.parse(localStorage.getItem('CACHED_USER'));
if(cachedUser) {
  Sentry.setUser({
    id: cachedUser.id ?? '',
    email: cachedUser.email ?? '',
    username: cachedUser.username ?? '',
  })
}
if (environment.production) {
  enableProdMode();
}
document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => {
      
    });
});
