<div class="container shippn-home-page-container">
    <div class="row">
        <div class="col-12">

            <div class="countries-wrapper">

                <div class="countries-item" *ngFor="let country of countriesList">
                    <div class="flag-holder">
                        <img [src]="'https://www.shippn.com/assets/img/flags/'+country.country_flag" alt="flag" class="country-flag">
                    </div>
                    <div>
                        <span>{{country.code}}</span> <br>
                        <span>{{country.name}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>