<link rel="preload" as="image" href="../../../assets/perfume/PerfumeAtelierBanner.webp">
<div style="background-image: url('../../../assets/perfume/PerfumeAtelierBanner.webp');background-size: cover;background-position: center;height: 350px;text-transform: capitalize;font-size: 20px;color: #ffffff;padding: 10px 15px;border-bottom: 1px solid transparent;border-top-left-radius: 3px;border-top-right-radius: 3px;"
    class="shorkstore-bg">
    <div class="container shippn-homepage-container">
        <div class="row">
            <div class="col-md-12">
                <div style="height: 330px;display: grid;align-items: center;text-align: center;">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container shippn-homepage-container font-tf2">
    <div class="row">
        <div style="margin-bottom: 20px;top: -85px;" class="col-md-12 d-none d-sm-none d-xs-none d-md-block d-lg-block">
            <div style="box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);border-radius: 10px;">
                <div class="row"
                    style="height: 85px;background-color: #222222ad;border-top-left-radius: 10px;border-top-right-radius: 10px;margin: 0;display: flex;align-items: center;">
                    <div class="col-md-2 col-lg-2">
                        <div
                            style="display: flex; flex-direction: column; align-items: center;height: 50px;border-top-left-radius: 7px;border-top-right-radius: 7px;">
                            <div style="text-align: center;position: relative;top: 20px;">
                                <img style="width: 95px;height: 95px;object-fit: cover;border-radius: 50%;border: 2px solid black;"
                                    class="img-responsive img-circle" src="{{memberDetails?.data.profile_image_url}}"
                                    alt="Shippn Host profile picture">
                            </div>
                        </div>
                    </div>
                    <div style="text-align: left;" class="col-md-5 col-lg-6">
                        <div class="font-tf2-bold">
                            <h1 *ngIf="memberDetails"
                                style="font-size: 16px;font-weight: 900;color: white;margin-bottom: 5px;"
                                translate="no">Perfume Atelier</h1>
                            

                        </div>
                    </div>

                </div>
                <div style="margin: 0; height: 85px;" class="row font-tf2-bold">
                    <div style="display: flex; width: 100%; align-items: center; justify-content: center;">
                        
                        <p style="font-size: 20px;color: black;line-height: 1.5;">
                            <i>Your scent makes you special</i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-bottom: 20px;top: -85px;" class="col-12 d-block d-sm-block d-xs-block d-md-none d-lg-none">
            <div style="box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);border-radius: 10px;">
                <div class="row"
                    style="height: 85px;background-color: #222222ad;border-top-left-radius: 10px;border-top-right-radius: 10px;margin: 0;display: flex;align-items: center;">
                    <div class="col-md-12">
                        <div style="height: 50px;border-top-left-radius: 7px;border-top-right-radius: 7px;">
                            <div style="text-align: center;position: relative;top: 15px;">
                                <img style="width: 95px;height: 95px;object-fit: cover;border-radius: 50%;border: 2px solid black;"
                                    class="img-responsive img-circle" src="{{memberDetails?.data.profile_image_url}}"
                                    alt="Host Profile Picture, avatar">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div style="margin-top: 55px;text-align: center;" class="col-md-12">
                        <h4 *ngIf="memberDetails"
                            style="font-size: 16px;font-weight: 900;color: black;margin-bottom: 5px;" translate="no">
                            Perfume Atelier</h4>
                    </div>
                    <div style="text-align: left;margin-top: 20px;margin-left: 30px;margin-right: 30px;padding-left: 0;padding-right: 0;padding-bottom: 10px;"
                        class="col-md-12">

                    </div>

                    <div style="text-align: left;margin-top: 10px;margin-left: 30px;margin-right: 30px;padding-left: 0;padding-right: 0;padding-bottom: 10px;"
                        class="col-md-12">

                    </div>
                </div>
            </div>
        </div>
        <!-- Host Store Start -->
        <div class="col-md-12" style="top: -75px; padding-bottom: 40px;"
            *ngIf="hostStoreProducts && hostStoreProducts.length > 0 && memberDetails?.is_store_active == 1">

            <div style="box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.16);border-radius: 10px;" class="host-store-container">
                <h4 class="header-underline-black-left store-title-tf2">Perfume Atelier's Store</h4>
                <div class="host-store-items-wrapper">
                    <div class="host-store-item" *ngFor="let storeItem of hostStoreProducts">
                        <div class="host-store-item-image">
                            <img src="{{storeItem.images[0]}}" alt="store item">
                        </div>
                        <div class="host-store-item-detail">
                            <div class="host-store-item-detail-name">
                                <h6>{{storeItem.name |slice:0:50}} <span *ngIf="storeItem?.name?.length > 50">...</span>
                                </h6>
                            </div>
                        </div>
                        <div class="host-store-item-footer">
                            <div class="host-store-item-detail-price">
                                <h6>${{storeItem.price}}</h6>
                            </div>
                            <button class="btn host-store-item-add-button"
                                (click)="createBFMBasketWithStoreItem(storeItem)"
                                style="background-color: #ff6c36!important;font-size: 11px;">Add to basket</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Host Store End -->
    </div>
</div>