import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { WatcherService } from './services/watcher.service';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import * as AuthActions from './store/shared/auth/auth.actions'



@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor(
    private route: Router,
    private watcherService: WatcherService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes('shippn') && (error.status === 401)) {

          this.toastr.error('Your session has expired. Please login again.', 'Session Expired');
          localStorage.removeItem('token');
          localStorage.removeItem('is_email_verified');
          localStorage.removeItem('userId');
          localStorage.removeItem('is_phone_verified');
          this.store.dispatch(AuthActions.Logout())
          this.route.navigate(['/', 'en', 'signin'])
        }
        if((error.status === 405)){
          this.toastr.error('You are not authorized to perform this action.', 'Unauthorized');
          this.route.navigate(['/', 'en', 'home'])
        }
        return throwError(error);
      })
    );
  }
}
