<footer style="background: #444;" *ngIf="!isShopNowActive">
  <!-- Footer Partners -->
  <div class="footer-partners">
    <div class="shippn-homepage-container container">
      <div class="row footer-partners-row">
        <div class="col-4 col-md-2 footer-partners-image-container">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-DHL_Logo.svg" alt="DHL Logo">
        </div>
        <div class="col-4 col-md-2 footer-partners-image-container">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-FedEx_Wordmark.svg" alt="Fedex logo">
        </div>
        <div class="col-4 col-md-2 footer-partners-image-container">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-TNT_Logo.svg" alt="TNT Logo">
        </div>
        <div class="col-4 col-md-2 footer-partners-image-container">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-ups.svg" alt="Ups logo">
        </div>
        <div class="col-4 col-md-2 footer-partners-image-container partner-logo-fix">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-australia-post-logo.svg" alt="Australia Post logo">
        </div>
        <div class="col-4 col-md-2 footer-partners-image-container partner-logo-fix">
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/partner-La_Poste_logo.svg" alt="La poste logo">
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Partners-->
  <div style="background-color: #222;color: #ddd;">
    <div style="font-family: 'Roboto';padding-top: 20px;padding-bottom: 40px;" id="footer"
      class="shippn-homepage-container container">

      <div class="row mobile-center" *ngIf="!hello10Variant">
        <div class="col-12 col-md-2">
          <img fetchpriority="low"loading="lazy" class="footer-logo"
            src="https://d1we2hgzmci25y.cloudfront.net/assets/img/shippn-logo-w.png" alt="Shippn Logo">
        </div>
        <div class="col-6 col-md-2">
          <h4 class="header-underline-white-left"
            style="font-size: 16px;font-weight: bold;margin-bottom: 27px;color: white;">
            Company</h4>

          <ul>
            <li><a [routerLink]="['/', lang,'about']" target="_self">About</a></li>
            <li><a [routerLink]="['/', lang,'contact-us']" target="_self">Contact Us</a></li>
            <li><a [routerLink]="['/', lang,'faq']" target="_self">FAQ</a></li>
            <li><a [routerLink]="['/', lang,'help']" target="_self">Help</a></li>
            <li><a [routerLink]="['/', lang,'terms']"  target="_self">Terms & Conditions</a></li>
            <li><a [routerLink]="['/', lang,'privacy-policy']" target="_self">Privacy Policy</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2">
          <h4 class="header-underline-white-left"
            style="font-size: 16px;font-weight: bold;margin-bottom: 27px;color: white;">
            Shoppers</h4>

          <ul>
            <li><a [routerLink]="['/', lang,'howitworks-for-shoppers']" target="_self">How It Works</a></li>
            <li><a [routerLink]="['/', lang,'shipping-cost-calculator']" target="_self"
                style=" display: flex; justify-content: flex-start; text-align: left!important;">Shipping Cost
                Calculator</a></li>
            <!--<li><a href="/brands" target="_self">Brands</a></li>-->
            <li><a [routerLink]="['/', lang,'trust-safety']" target="_self">Trust & Safety</a></li>
            <li *ngIf="!token"><a [routerLink]="['/', lang,'signup']" target="_self">Become a Shopper</a></li>
            <li><a [routerLink]="['/', lang,'signin']" target="_self">Shopper Login</a></li>
            <li><a [routerLink]="['/', lang,'prohibited-items']" target="_self">Prohibited Items</a></li>
            <li><a [routerLink]="['/', lang,'stores']" target="_self">Top Global Stores</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2">
          <h4 class="header-underline-white-left"
            style="font-size: 16px;font-weight: bold;margin-bottom: 27px;color: white;">
            Shop From</h4>
          <ul>
            <li><a [routerLink]="['/', lang,'shop-from', 'us']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">U.S.A.</a></li>
            <li><a [routerLink]="['/', lang,'shop-from', 'ca']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">Canada</a></li>
            <li><a [routerLink]="['/', lang,'shop-from', 'au']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">Australia</a></li>
            <li><a [routerLink]="['/', lang,'shop-from', 'uk']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">United Kingdom</a></li>
            <li><a [routerLink]="['/', lang,'shop-from', 'nl']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">Netherlands</a></li>
            <li><a [routerLink]="['/', lang,'shop-from', 'tr']" [queryParams]="loggedUser?.has_orders === false ? { user: 'new' } : null">Türkiye</a></li>
          </ul>
        </div>
        <div class="col-6 col-md-2">
          <h4 class="header-underline-white-left"
            style="font-size: 16px;font-weight: bold;margin-bottom: 27px;color: white;">
            Hosts</h4>
          <ul>
            <li><a href="{{lang}}/howitworks-for-hosts" target="_self">How It Works</a></li>
            <li *ngIf="!token"><a href="{{lang}}/become-a-host" target="_self">Become a Host</a></li>
            <li><a href="{{lang}}/responsible-shipping" target="_self">Responsible Shipping</a></li>
            <li><a href="{{lang}}/shipping-standards" target="_self">Shipping Standards</a></li>
            <li><a href="{{lang}}/shipper-network" target="_self">Shipper Network</a></li>
          </ul>
        </div>
        <div class="col-12 col-md-2 footer-application-area">
          <div style="margin-top: 10px;">
            <a target="_blank" href="https://www.facebook.com/shippn" rel="external nofollow"
              style="text-decoration: none;">
              <img fetchpriority="low"loading="lazy" style="width:10px;margin-right: 10px;"
                src="../assets/img/footer/svg/social/social-facebook.svg" alt="facebook svg">
            </a>
            <a target="_blank" href="https://twitter.com/shippncom" rel="external nofollow"
              style="text-decoration: none;">
              <img fetchpriority="low"loading="lazy" style="width:17px;margin-right: 10px;"
                src="../assets/img/footer/svg/social/social-x.png" alt="twitter svg">
            </a>
            <a target="_blank" href="https://www.instagram.com/shippncom/" rel="external nofollow"
              style="text-decoration: none;">
              <img fetchpriority="low"loading="lazy" style="width:17px;margin-right: 10px;"
                src="../assets/img/footer/svg/social/social-instagram.svg" alt="instagram svg">
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/shippn/" rel="external nofollow"
              style="text-decoration: none;">
              <img fetchpriority="low"loading="lazy" style="width:18px;" src="../assets/img/footer/svg/social/social-linkedin.svg"
                alt="linkedin svg">
            </a>
          </div>
          <div class="row">
            <div class="col-md-12" style="margin-top: 20px;">
              <div style="margin-top: 10px;">
                <a href="https://www.shippn.com/blog" target="_blank"
                  style="background-color: transparent;border: 1px solid white;color: white;font-size: 12px;"
                  class="btn btn-primary footer-shippn-blog">Shippn Blog <i style="font-size: 11px;margin-left: 4px;"
                    class="fas fa-long-arrow-alt-right"></i></a>
              </div>
              <div style="margin-top: 10px;">
                <a target="_blank" href="https://itunes.apple.com/us/app/shippn/id1321290150" rel="external nofollow"
                  style="margin-top: 10px;">
                  <img fetchpriority="low"loading="lazy" class="footer-app-store" style="padding: 4px;border-radius: 5px;"
                    src="../assets/img/app-store.webp" alt="app store badge" width="85%" height="100%">
                </a>
              </div>
              <div style="margin-top: 10px;">
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.shippn.android"
                  rel="external nofollow">
                  <img fetchpriority="low"loading="lazy" class="footer-google-play" style="padding: 4px;border-radius: 5px;"
                    src="../assets/img/play-store.webp" alt="play store badge" width="85%" height="100%">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 40px;" class="row">
        <div class="col-md-2 col-sm-12 col-xs-12 hello10Variant-logo-holder" style="display: flex;align-items: center;">
          <img fetchpriority="low"loading="lazy" class="footer-logo"
            src="https://d1we2hgzmci25y.cloudfront.net/assets/img/shippn-logo-w.png" alt="Shippn Logo" *ngIf="hello10Variant" style="margin: auto!important;">
        </div>
        <div class="col-md-8">
          <h5 style="color: white;font-weight: normal;text-align: center;font-size: 12px;">Secure payment guaranteed by
          </h5>
          <div style="text-align: center;margin-top: 10px;">
            <img fetchpriority="low"loading="lazy" style="width: 6%;margin-right: 15px;" src="../assets/img/footer/svg/payment-visa.svg"
              alt="visa svg">
            <img fetchpriority="low"loading="lazy" style="width: 9%;margin-right: 15px;" src="../assets/img/footer/svg/payment-paypal.svg"
              alt="paypal svg">
            <img fetchpriority="low"loading="lazy" style="width:6%;margin-right: 15px;"
              src="../assets/img/footer/svg/payment-american-express.svg" alt="american express svg">
            <img fetchpriority="low"loading="lazy" style="width:6%;margin-right: 15px;"
              src="../assets/img/footer/svg/payment-discover_card_logo.svg" alt="discover svg">
            <img fetchpriority="low"loading="lazy" style="width: 6%;margin-right: 15px;"
              src="../assets/img/footer/svg/payment-mastercard-logo.svg" alt="mastercard svg">
            <img fetchpriority="low"loading="lazy" style="width: 7%;" src="../assets/img/footer/svg/payment-gpay.svg"
              alt="gpay google pay svg">
            <img fetchpriority="low" loading="lazy" style="width: 10%;" src="../assets/img/footer/payment-klarna.webp" alt="klarna payment logo">
            <img fetchpriority="low"loading="lazy" style="width: 10%;margin-right: 15px;"
              src="../assets/img/footer/svg/powered-by-stripe.svg" alt="powered by stripe svg">
          </div>
        </div>
        <div class="col-md-2 col-12 footer-application-area" style="margin-top:10px">
          <h5 style="color: white;font-weight: bold;font-size: 12px;">Your data protected by</h5>
          <img fetchpriority="low"loading="lazy" src="../assets/img/footer/svg/comodo-ssl-certificate.svg" width="80px"
            alt="comodo ssl certificate svg">
        </div>
      </div>
    </div>
  </div>
</footer>