<div class="banner-container">
    <div class="globe-dots"></div>
    <svg class="animated-path" viewBox="0 0 400 250" xmlns="http://www.w3.org/2000/svg">
        <path class="path" d="M50 100 C 150 50, 250 150, 350 80" stroke="rgba(255,255,255,0.3)" stroke-width="2" fill="none"/>
    </svg>
    <div class="banner-content">
        <div class="banner-text">
            <!-- Logo removed as requested -->
            <div class="tagline">World's #1 Mail Forwarding Service with Unmatched Global Coverage</div>
            <div class="banner-features">
                <div class="feature">
                    <div class="feature-icon">✓</div>
                    <span>Shop from 40+ countries</span>
                </div>
                <div class="feature">
                    <div class="feature-icon">✓</div>
                    <span>Ship to 200+ countries</span>
                </div>
                <div class="feature">
                    <div class="feature-icon">✓</div>
                    <span>Fast Delivery and Secure Handling</span>
                </div>
            </div>
            <a href="#" class="cta-button" [routerLink]="['/', lang, 'signup']">Start Shopping</a>
        </div>
        <div class="banner-illustration">
            <div class="world-map"></div>
            <div class="package package-1">📦</div>
            <div class="package package-2">📦</div>
            <div class="package package-3">📦</div>
        </div>
    </div>
</div>