import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WatcherService } from 'src/app/services/watcher.service';
import { AppState } from 'src/app/store/app.reducer';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  token;
  loggedUser;

  constructor(
    private watcherService: WatcherService,
    private store: Store<AppState>,
    private router: Router
  ) {

  }

  lang = 'en';
  hello10Variant: boolean = false;
  isShopNowActive: boolean = false; 
  ngOnInit(): void {
    this.store.select('auth').subscribe(state => {
      this.token = state.token;
      this.loggedUser = state.user ?? null;
    })
    this.watcherService.getLang().subscribe(
      lang => {
        this.lang = lang;
      }
    )
    this.router.events.forEach(ev => {

      if( ev instanceof NavigationEnd){
        if(ev.url.includes('hi10')){
          this.hello10Variant = true;
        } else {
          this.hello10Variant = false;
        }

        if(ev.url.includes(`${this.lang}/shopnow`)){
          this.isShopNowActive = true;
        } else {
          this.isShopNowActive = false;
        }
      }
    })
  }

}
